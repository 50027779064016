<template>
  <div class="all css-helper">
    <div class="header flex">
      <div class="left flex-item flex-item-center">
        <img
          src="../assets/login/logo.png"
          @click="$router.push('/')"
          class="cursor-pointer"
        />
      </div>
      <div class="right flex-item flex-item-center flex-1">
        欢迎来到顺义博物馆
      </div>
    </div>
    <div class="container">
      <div class="pos">
        <div class="loginCenter">
          <img src="../assets/login/logo.png" alt="" class="img1" />
          <div class="deng">
            <span @click="tap(1)" :class="act == 1 ? 'zongColor' : ''">
              账号密码登录
              <br />
              <div class="bor oran" :class="act == 1 ? 'orans' : ''"></div>
            </span>
            <span @click="tap(2)" :class="act == 2 ? 'zongColor' : ''">
              手机快捷登录
              <div class="bor oran" :class="act == 2 ? 'orans' : ''"></div>
            </span>
          </div>
          <div class="userLogin" v-if="act == 2">
            <div class="listCode phone-input">
              <div class="username">
                <input
                  type="text"
                  placeholder="请输入手机号码"
                  v-model="phoneLogin.phone"
                />
              </div>
            </div>
            <div class="listCode flex phone-input">
              <div class="code flex-item flex-item-center">
                <input
                  type="text"
                  placeholder="请输入验证码"
                  v-model="phoneLogin.code"
                />
              </div>
              <img
                class="codeImg flex-item flex-item-center"
                :src="codeImage"
                @click="imgCode"
                alt=""
              />
            </div>
            <div class="listCode phone-input flex">
              <div class="phonecode phonecode-input flex-item flex-item-center">
                <input
                  type="text"
                  placeholder="请输入手机验证码"
                  v-model="phoneLogin.yanzheng"
                />
              </div>
              <div
                class="codeBtn flex-item flex-item-center cursor-pointer"
                @click="sendCode"
              >
                {{ this.phoneLogin.smsButton.text }}
              </div>
            </div>
            <div class="xieyi flex">
              <el-checkbox
                v-model="checked2"
                class="login-fuxuan flex-item flex-item-center"
              ></el-checkbox>
              <span
                class="tongyi flex-item flex-item-center"
                style="color: #999999"
              >
                我已经同意阅读
                <span style="color: #666666"> 《用户协议及隐私政策》 </span>
              </span>
              <div class="link"></div>
            </div>
            <button class="loginBtn" @click="shoujiLogin">登录</button>
            <div class="zhuce">
              <span @click="zhuce" class="cursor-pointer">立即注册</span>
              <li class="rightes cursor-pointer">忘记密码？</li>
            </div>
          </div>

          <div class="userLogin" v-if="act == 1">
            <div class="username mab">
              <input
                type="text"
                placeholder="请输入账号"
                v-model="zhanglogin.zhanghao"
              />
            </div>
            <div class="username mab">
              <input
                type="password"
                placeholder="密码"
                v-model="zhanglogin.password"
              />
            </div>
            <div class="xieyi flex">
              <el-checkbox
                v-model="checked1"
                class="login-fuxuan flex-item flex-item-center"
              ></el-checkbox>
              <span
                class="tongyi flex-item flex-item-center"
                style="color: #999999"
              >
                我已经同意阅读
                <span style="color: #666666"> 《用户协议及隐私政策》 </span>
              </span>
              <div class="link"></div>
            </div>
            <div class="btn">
              <button class="loginBtn" @click="userLogin">登录</button>
            </div>
            <div class="zhuce">
              <span @click="zhuce" class="cursor-pointer">立即注册</span>
              <li @click="passwords" class="rightes cursor-pointer">
                忘记密码？
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
        
  </div>
</template>

<script>
import OutLine from "../components/Dialog";
import SmsButtonAgent from "../components/smsButtonAgent";
export default {
  data() {
    return {
      codesubstr: "",
      codeImage: "",
      act: "1",
      zhanglogin: {
        password: "",
        zhanghao: "",
      },
      phoneLogin: {
        phone: "",
        code: "",
        yanzheng: "",
        smsButton: SmsButtonAgent(),
      },

      title: "asd",
      flag: false,
      checked1: true,
      checked2: true,
    };
  },
  components: {
    OutLine,
  },
  created() {},
  mounted() {
    this.imgCode();
    document.title="登录"
  },
  methods: {
    zhanghaoLoginCheck() {
      if (this.zhanglogin.zhanghao === "") {
        this.showPopupMessage("用户名不能为空", false);
        return false;
      } else if (this.zhanglogin.password === "") {
        this.showPopupMessage("密码不能为空", false);
        return false;
      } else if (this.checked1 === false) {
        this.showPopupMessage("未同意用户隐私及政策");
        return false;
      } else {
        return true;
      }
    },
    phoneLoginImgCodeCheck() {
      var reg_phone = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (this.phoneLogin.phone === "") {
        this.showPopupMessage("手机号不能为空", false);
        return false;
      } else if (!reg_phone.test(this.phoneLogin.phone)) {
        this.showPopupMessage("手机号格式不正确", false);
        return false;
      } else if (this.phoneLogin.code === "") {
        this.showPopupMessage("图片验证码不能为空", false);
        return false;
      } else {
        return true;
      }
    },
    phoneLoginCheck() {
      var reg_phone = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;

      if (this.phoneLogin.phone === "") {
        this.showPopupMessage("手机号不能为空", false);
        return false;
      } else if (!reg_phone.test(this.phoneLogin.phone)) {
        this.showPopupMessage("手机号格式不正确", false);
        return false;
      } else if (this.phoneLogin.code === "") {
        this.showPopupMessage("图片验证码不能为空", false);
        return false;
      } else if (this.phoneLogin.yanzheng === "") {
        this.showPopupMessage("手机验证码不能为空", false);
        return false;
      } else if (this.checked2 === false) {
        this.showPopupMessage("未同意用户及隐私政策", false);
        return false;
      } else {
        return true;
      }
    },
    imgCode() {
      let rand = Math.random().toString(12).substr(13);
      this.codesubstr = rand;
      let query = {
        imgcodeid: rand,
      };
      this.$fetch(`/getVerify?imgcodeid=${rand}`, query).then((res) => {
        this.codeImage =
          "http://www.symuseum.cn/api/getVerify/?imgcodeid=" + rand;
      });
    },
    tap(val) {
      this.act = val;
    },
    sendCode() {
      if (!this.phoneLoginImgCodeCheck()) {
        return;
      }

      //发送验证码
      let cb = this.phoneLogin.smsButton.request();
      if (cb === false) {
        return;
      }
      let query = {
        phone: this.phoneLogin.phone,
        type: "login_confirm",
        image_verify_code: this.phoneLogin.code,
        imgcodeid: this.codesubstr,
      };
      this.$post("/send_sms", query).then((res) => {
        if (res.code !== 1) {
          cb(false);
          this.showPopupMessage(res.msg, false);
        } else {
          cb(true);
          this.showPopupMessage("验证码发送成功", true);
        }
      });
    },
    shoujiLogin() {
      if (!this.phoneLoginCheck()) {
        return;
      }
      let query = {
        phone: this.phoneLogin.phone,
        verify_code: this.phoneLogin.yanzheng,
        source: "pc",
        image_verify_code: this.phoneLogin.code,
        imgcodeid: this.codesubstr,
      };
      this.$post("/quick_login", query).then((res) => {
        if (res.code === 1) {
          localStorage.setItem("token", res.data.token);
          this.showPopupMessage(res.msg, true);
          setTimeout(() => {
            this.$router.push("/");
          }, 2000);
        } else {
          this.showPopupMessage(res.msg, false);
        }
      });
    },
    obtaincode() {},
    userLogin() {
      if (this.zhanghaoLoginCheck() === false) {
        return;
      }
      let query = {
        username: this.zhanglogin.zhanghao,
        password: this.zhanglogin.password,
        source: "pc",
      };
      this.$post("/login", query).then((res) => {
        if (res.code === 1) {
          localStorage.setItem("token", res.data.token);
          this.showPopupMessage(res.msg, true);
          setTimeout(() => {
            this.$router.push("/");
          }, 2000);
        } else {
          this.showPopupMessage(res.msg, false);
        }
      });
    },
    zhuce() {
      this.$router.push("/enroll");
    },
    passwords() {
      this.$router.push("/password");
    },
  },
};
</script>

<style scoped>
button {
  cursor: pointer;
}
.zongColor {
  color: #bd955d;
  font-weight: 600;
}
.oran {
  opacity: 0;
}
.orans {
  opacity: 1;
}
.all {
  height: 100%;
  width: 100%;

  overflow: hidden;
}
.header {
  padding: 0 160px;
  height: 80px;
}

.left {
  height: 43px;
}
.right {
  font-size: 14px;
  line-height: 97px;
  height: 97px;
  text-align: right;
}
.container {
  width: 100%;
  height: 100%;
  background: url("../assets/login/bg.jpg") top center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pos {
  height: 700px;
  width: 1121px;
  margin-left: 7.6%;

  background: url("../assets/login/loginback.png") no-repeat;
  background-size: 1121px 700px;
}
.loginCenter {
  height: 750px;
  padding: 90px 0 0 120px;
  width: 650px;
  text-align: center;
  box-sizing: border-box;
}
.loginCenter .img1 {
  margin: 0 160px 30px 0;
}
.deng {
  width: 360px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 15px;
  color: #666;
}
.deng span {
  cursor: pointer;
}
.loginCenter div div {
  font-weight: 400;
}
.bor {
  width: 30px;
  border-top: 2px #bd955d solid;
  height: 2px;
  margin: 6px auto 0;
}
.userLogin {
  width: 360px;
  height: 80%;

  padding-bottom: 10px;
  box-sizing: border-box;
  position: relative;
}
.username {
  padding: 15px;
  height: 50px;
  width: 357px;
  box-sizing: border-box;
  background: rgb(249, 250, 252);
  border-radius: 10px;
}
.phone-input {
  margin-bottom: 16px;
}
.mab {
  margin-bottom: 16px;
}
.username input {
  width: 100%;
  background: none;
  border: none;
  outline: none;
}
.code {
  padding: 15px;
  height: 50px;
  width: 237px;
  box-sizing: border-box;
  background: rgb(249, 250, 252);

  border-radius: 10px;
}
.code input {
  width: 100%;
  background: none;
  border: none;
  outline: none;
}
.codeImg {
  width: 76px;
  height: 37px;
  margin-left: 20px;
}
.phonecode {
  padding: 15px;
  height: 47px;
  box-sizing: border-box;
  background-color: #e1e6f0;
  border-radius: 10px;
  background: rgb(249, 250, 252);
}
.phonecode-input {
  width: 237px;
  box-sizing: border-box;
}
.phonecode input {
  float: left;
  padding-top: 5px;
  padding-left: none;
  height: 20px;
  box-sizing: border-box;
  background-color: none;
  border: none;
  outline: none;
  background-color: #e1e6f0;
  margin-top: -5px;
  background: rgb(249, 250, 252);
}
.codeBtn {
  width: 115px;
  height: 47px;
  background: #bd955d;
  border-radius: 10px;
  text-align: center;
  line-height: 47px;
  color: white;
  font-size: 14px;
}
.listCode {
  height: 50px;
}
.xieyi {
  width: 100%;
  margin-top: 4px;
}
.login-fuxuan {
  border-radius: 10px;
  margin-right: 10px;
}
.tongyi {
  font-size: 14px;
}

.zhuce {
  font-size: 14px;
  width: 100%;
  text-align: center;
  line-height: 15px;
  color: #777;
}
.zhuce span {
  padding-right: 20px;
  height: 15px;
  border-right: 2px solid #cccccc;
}
.zhuce li {
  padding-left: 20px;
  display: inline-block;
  height: 15px;
}
.loginBtn {
  width: 100%;
  height: 48px;
  outline: none;
  border: none;
  margin: 30px 0 40px 0;
  border-radius: 12px;
  color: #ffffff;
  background-color: #bd955d;
  font-size: 18px;
}
</style>
